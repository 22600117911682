import { styled} from "@mui/material";
 
  export const Container = styled("div")` 
  width: 100%; 
  height: 100vh;`;
  export const Card = styled("div")` 
  background: #FFFFFF;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 29.4316px;
  width: 380px;
  height: 215px;
  padding: 24px 0;
  text-align: center;
  `;
  export const AwaitingListBody = styled("div")`
  width: 100%;
  height: 400px;
  max-height: auto;
  background: #FFF;
  box-shadow: 0px 4px 31px -4px rgba(0, 0, 0, 0.25);
  border-radius: 10.28px;
  margin: 5% 0;
  padding: 1%;
  `;
  export const AwaitingListTable = styled("table")`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  `;
 export const ViewButton = styled("button")`
 width: 115px;
  height: 44px;
  background: #10609F;
  box-shadow: 0px 2.92453px 15.3538px -4.38679px rgba(16, 96, 159, 0.25);
  border-radius: 5.84906px;
  color: #FFFFFF;
  `;
